define([
  'jquery',
  'application',
  'modules/common/components/component',
  'modules/common/components/managers/configuration',
  './../collections/users',
  './../models/user',
  'modules/upx/components/apiClient',

  './../events/error/apiConvertable',
  './../events/error/apiReady',
  './../events/error/auth',
  './../events/error/general',
  './../events/error/inputValidation',

  './../events/error/internal',
  './../events/error/license',
  './../events/error/outputValidation',
  './../events/error/permission',
  './../events/error/validation',
  './../events/error/other',
  'modules/common/components/string',
],
($, App, Component, ConfigurationManager, Users, User, Upx,
  ApiConvertableEvent, ApiReadyEvent, AuthEvent, GeneralEvent, InputValidationEvent,
  InternalEvent, LicenseEvent, OutputValidationEvent, PermissionEvent, ValidationEvent, OtherEvent) => {
  const UPX = Component.extend({
    /**
             *
             * @param url
             * @returns {*}
             */
    getResourceUrl(url) {
      // Checking if the url is not undefined or an empty string
      if (url !== undefined && !!url) {
        if (url.startsWith('http') || url.startsWith('file:/') || url.startsWith('cdvfile://')) {
          // Replace spaces from URL's which CAN cause issues.
          url = url.replaceAll(' ', '%20');

          return url;
        }
        let server = App.settings.upx.resourceUrl
                            || App.settings.upx.server;

        if (url.startsWith('/')) {
          url = url.substr(1);
        }
        if (server.endsWith('/')) {
          server = server.slice(0, -1);
        }
        // Removes spaces from URL's which CAN cause issues.
        url = url.replaceAll(' ', '%20');

        return `${server}/${url}`;
      }
      return '';
    },
    /**
             * sets authentication on call based on user
             * called every time "call" is done
             * @var user User model
             * @protected
             */
    setAuth(user, upx) {
      upx.setUser(user.get('user'));

      if (user.get('rights') == 'subuser') {
        upx.setSubaccount(user.get('subaccount'));
      }

      if (user.get('mode') == 'password') {
        upx.setPassword(user.get('password'));
      } else if (user.get('mode') == 'hash') {
        upx.setHash(user.get('hash'));
      } else if (user.get('mode') == 'apikey') {
        upx.setApikey(user.get('apikey'));
      }
    },

    getAccountName() {
      return App.settings.upx.account;
    },
    getApiUrl() {
      return App.settings.upx.server;
    },
    getUpxClient(user) {
      const upx = new Upx();
      // Check if we have upx settings
      if (App.settings.upx === undefined) {
        throw new Error('UPX settings are not loaded.');
      }
      // This adds the required xdebug to the call. Its spammy, But its for easy local testing & development.
      if (App.settings.upx.xdebug) {
        upx.setXDebug(App.settings.upx.xdebug);
      }
      const configuration = App.settings.upx;
      upx.setServer(configuration.server);
      upx.setAccount(configuration.account);
      if ('client_name' in configuration) {
        upx.setClientName(configuration.client_name);
      }

      if (!user) {
        // If there are no active user
        const { fallbackUser } = configuration;
        if (fallbackUser) {
          this.setAuth(new User(fallbackUser), upx);
        } else {
          // there are no fallback user defined, use anonymous
          upx.setAnonymous();
        }
      } else {
        user.fetch();
        this.setAuth(user, upx);
      }
      return upx;
    },

    genCallId() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    },
    /**
             *
             * @param user
             * @param module
             * @param method
             * @param parameters
             * @param ajaxOptions
             */
    callWithUser(user, module, method, parameters, ajaxOptions) {
      const deferred = $.Deferred();
      const self = this;
      try {
        ajaxOptions = $.extend({}, {
          timeout: 30000,
        }, ajaxOptions);

        let delayDef = new $.Deferred();
        if (user && user.isRefreshing()) {
          const id = this.genCallId();
          console.log(`[SESSION] Call(${id}) ${module}::${method} delayed until session finishes refresh`);
          delayDef = user.waitUntilDoneRefreshing();
          delayDef.always(() => {
            console.log(`[SESSION] Call(${id}) ${module}::${method} resumed`);
          });
        } else {
          delayDef.resolve();
        }

        // wait until refreshing is finished
        delayDef.always(
          () => {
            try {
              // refetch the user to make sure we have newest config
              const upx = self.getUpxClient(user);
              $.when(upx.call(module, method, parameters, ajaxOptions)).then(
                (response) => {
                  deferred.resolve(response);
                },
                (response) => {
                  self.handleCallError(response, deferred, module, method);
                },
              );
            } catch (e) {
              deferred.reject({
                success: false,
                error: e.message,
                e,
              });
              console.error('Error within upx component', e);
            }
          },
        );
      } catch (e) {
        deferred.reject({
          success: false,
          error: e.message,
          e,
        });
        console.error('Error within upx component', e);
      }

      return deferred.promise();
    },
    /*
             * Call the given method on the UPX server
             * Automaticly uses the active user from the collection
             * @var module The module which contains the method
             * @var method The Method which needs to be executed
             * @var parameters (optional) The parameters which needs to be passed to the method
             * @var ajaxOptions (optional) Options which will be set on the ajax request
             * @public
             * @returns promise object
             * @throws Error
             */
    call(module, method, parameters, ajaxOptions) {
      // Try to find an active user
      const user = Users.findWhere({
        active: true,
      });
      return this.callWithUser(user, module, method, parameters, ajaxOptions);
    },

    _warningClasses: [
      'Auth', 'Lookup', 'PaymentModule::InvalidGiftCard',
    ],

    handleCallError(response, deferred, module, method) {
      const errorMessage = `UPX.call ${module}::${method} failed (ref=${response.ref}) [${response.class}] ${response.error}`;
      if (this._warningClasses.indexOf(response.class) === -1) {
        console.error(errorMessage);
      } else {
        console.warn(errorMessage);
      }

      if (response.class == 'ApiConvertable') {
        var event = new ApiConvertableEvent(response);
      } else if (response.class == 'ApiReady') {
        var event = new ApiReadyEvent(response);
      } else if (response.class == 'Auth') {
        var event = new AuthEvent(response);
      } else if (response.class == 'General') {
        var event = new GeneralEvent(response);
      } else if (response.class == 'InputValidation') {
        var event = new InputValidationEvent(response);
      } else if (response.class == 'Internal') {
        var event = new InternalEvent(response);
      } else if (response.class == 'License') {
        var event = new LicenseEvent(response);
      } else if (response.class == 'OutputValidation') {
        var event = new OutputValidationEvent(response);
      } else if (response.class == 'Permission') {
        var event = new PermissionEvent(response);
      } else if (response.class == 'Validation') {
        var event = new ValidationEvent(response);
      } else {
        var event = new OtherEvent(response);
      }

      event.trigger();
      deferred.reject(response);
    },
    /*
             * Prepares a call wrapped in a function so it can be executed later on
             * @var call The function which contains the call
             * @var onSuccess What to do when the call succeeds
             * @var onError What to do when the call fails
             * @public
             * @returns function
             */
    prepareCall(call, onSuccess, onError) {
      return (new Upx()).prepareCall(call, onSuccess, onError);
    },

    /*
             * Prepares a multiple prepared calls wrapped in a function so it can be executed later on
             * @var preparedCalls The prepared calls
             * @var onSuccess What to do when all calls succeeds
             * @var onError What to do when a prepared call fails
             * @public
             * @returns function
             */
    multiCall(preparedCalls, onSuccess, onError) {
      return (new Upx()).multiCall(preparedCalls, onSuccess, onError);
    },
    /**
             * steps over preparedCalls one by one, only starts new one if previous finished
             * @param preparedCalls
             * @param stopOnError
             */
    eachCall(preparedCalls, stopOnError) {
      const eachDef = new $.Deferred();
      try {
        if (typeof stopOnError === 'undefined') {
          stopOnError = true;
        }

        if (preparedCalls.length > 0) {
          const call = preparedCalls.shift();
          const next = () => {
            this.eachCall(preparedCalls, stopOnError).then(
              eachDef.resolve,
              eachDef.reject,
            );
          };

          const def = call();
          $.when(def).then(
            () => {
              next();
            },
            (error) => {
              if (!stopOnError) {
                next();
              } else {
                eachDef.reject(error);
              }
            },
          );
        } else {
          eachDef.resolve();
        }
      } catch (e) {
        eachDef.reject({
          error: e.message,
          e,
        });
      }
      return eachDef.promise();
    },
    /**
             *
             * @param call
             * @param retryNo
             * @param errorCallback
             * @param timeout
             */
    callWithRetry(call, retryNo, errorCallback, timeout) {
      timeout = timeout || 0; // defaults to 0 timeout.
      retryNo = retryNo || 3; // fix using the magic of 3
      errorCallback = errorCallback || (() => {});

      const preparedCalls = [];
      for (let i = 0; i < retryNo; i++) {
        preparedCalls.push(
          () =>
          // apply the error callback
            $.when(call()).fail(errorCallback),

        );
      }
      return this.callWithRetryForPreparedCalls(preparedCalls, timeout);
    },
    /**
             *
             * @param preparedCalls
             * @param timeout
             * @returns {*|jQuery|string|never}
             */
    callWithRetryForPreparedCalls(preparedCalls, timeout) {
      timeout = timeout || 0;
      const eachDef = new $.Deferred();
      if (preparedCalls.length === 0) {
        //
        throw new Error('No calls prepared');
      }

      const handleError = (e) => {
        eachDef.reject({
          error: e.message,
          e,
        });
      };

      try {
        const call = preparedCalls.shift();
        let failCall = eachDef.reject;
        if (preparedCalls.length > 0) {
          // there are still some calls, so we can retry
          failCall = () => {
            // retry on failure
            setTimeout(() => {
              try {
                this.callWithRetryForPreparedCalls(preparedCalls).then(
                  eachDef.resolve,
                  eachDef.reject,
                );
              } catch (e) {
                handleError(e);
              }
            }, timeout);
          };
        }

        $.when(call()).then(
          eachDef.resolve, // success we are done
          failCall,
        );
      } catch (e) {
        handleError(e);
      }
      return eachDef.promise();
    },

  });

  return new UPX();
});
