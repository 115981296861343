define([
  'jquery',
  'underscore',
  'backbone',
  './../components/upx',
  'modules/common/components/managers/cache',
  'modules/common/components/connection',

  '../events/error/offline',
  'crypto-js',
  'modules/shop/components/promisify',
  'modules/common/components/moment',
],
($, _, Backbone, UPX, CacheManager, Connection,
  OfflineEvent, CryptoJS, Promisify,
  Moment) => {
  function getProperty(object, property) {
    if (object == null) return void 0;
    const value = object[property];
    return (typeof value === 'function') ? object[property]() : value;
  }

  return Backbone.Collection.extend({

    initialize() {
      this.wrapper = UPX;

      this.cache = !!this.cache;
      this.cacheOptions = this.cacheOptions || {};
      if (this.cache) {
        this.enableCache();
      }

      this.offline = !!this.offline;
      this.offlineOptions = this.offlineOptions || {};
      if (this.offline) {
        this.enableOffline();
      }
    },
    /**
             *
             * @param options
             * @returns {null|*|model.cacheStorage}
             */
    getCacheStorage() {
      if (this.cache && this.cacheStorage == null) {
        this.cacheStorage = CacheManager.getStore(
          `C_${this.module}_${this.object}`,
          this.cacheOptions,
        );
      }
      return this.cacheStorage;
    },
    /**
             *
             */
    disableCache() {
      this.cache = false;
      this.cacheStorage = null;
    },
    /**
             *
             * @param options
             */
    enableCache(options) {
      this.cacheOptions = options || this.cacheOptions;
      this.cache = true;
    },
    /**
             *
             * @param options cache
             */
    enableOffline(options) {
      this.offline = true;
      if (options) {
        this.offlineOptions = options;
      }
      if (!this.cache) {
        this.enableCache();
      }
    },
    /**
             *
             * @param options cache
             */
    disableOffline() {
      this.offline = false;
    },
    parse(response) {
      if (response.success && response.response !== undefined) {
        this.setCount(response.response.count);
        this.setTotal(response.response.total);
        return response.response.data;
      }
      return {};
    },

    setTotal(total) {
      this.total = total;
    },

    setCount(count) {
      this.count = count;
    },

    setStart(start) {
      this.start = start;
    },

    getStart() {
      let start = 0;
      const parsedStart = parseInt(this.start);

      if (typeof (parsedStart) === 'number' && !isNaN(parsedStart)) {
        start = parsedStart;
      } else {
        console.warn(`Start was not a number, thus defaults to 0, was: ${this.start}`);
      }
      return start;
    },

    setLimit(limit) {
      this.limit = limit;
    },

    getLimit() {
      let limit = 50;
      const parsedLimit = parseInt(this.limit);
      if (typeof (parsedLimit) === 'number' && !isNaN(parsedLimit)) {
        limit = parsedLimit;
      } else {
        console.warn(`Limit was not a number, thus defaults to 50, was: ${this.limit}`);
      }
      return limit;
    },

    canFetchNext() {
      return this.start + this.limit < this.total && this.total > 0;
    },

    canFetchPrevious() {
      return this.start > 0 && this.models.length < this.start + this.models.length && this.total > 0;
    },

    fetchNext() {
      const options = {
        params: this.params || {},
        remove: false,
      };

      options.params.start = this.start + this.count;
      return this.fetch(options);
    },

    fetchPrevious() {
      const options = {
        params: this.params || {},
        remove: false,
      };

      options.params.start = this.start - this.limit;

      if (options.params.start < 0) {
        options.params.start = 0;
        options.params.limit = this.start - 1;
      }

      return this.fetch(options);
    },

    sync(method, model, options) {
      const parameters = options.params || {};
      if (parameters.limit !== undefined && parameters.limit != this.limit) {
        this.setLimit(parameters.limit);
      }
      if (parameters.start !== undefined && parameters.start != this.start) {
        this.setStart(parameters.start);
      }
      parameters.start = this.getStart();
      parameters.limit = this.getLimit();

      this.params = parameters;

      if (method == 'read' && model.cache) {
        return this.readFromCache(method, model, options);
      }
      return this.syncFromUpx(method, model, options);
    },

    readFromCache(method, model, options) {
      options = options || {};
      const self = this;
      const store = this.getCacheStorage();
      const syncDfd = $.Deferred();

      const queryId = CryptoJS.MD5(JSON.stringify(this.params)).toString();

      const upxCall = function () {
        const oldSuccess = options.success;
        options.success = function (resp) {
          if (resp.success !== undefined && resp.success) {
            // success lets save the response
            store.write(queryId, resp);
          }
          if (oldSuccess) {
            oldSuccess(resp);
          }
          syncDfd.resolve(resp.response);
        };
        const oldError = options.error;
        options.error = function (a, b, c) {
          if (oldError) {
            oldError(a, b, c);
          }
          syncDfd.reject(a, b, c);
        };
        self.syncFromUpx(method, model, options);
      };

      store.lookup(queryId).then((cacheResp) => {
        // there is cached object
        if (
          store.isFresh(cacheResp) // it is a fresh one
                            || (self.offline && !Connection.isOnline())// not fresh but offline mode
        ) {
          const resp = cacheResp.get('value');
          if (options && options.success) {
            options.success(resp);
          }
          syncDfd.resolve(resp);
        } else {
          // cache object not good we need to call upx
          // syncDfd will be resolved or rejected by upxCall
          upxCall();
        }
      }, upxCall, // no cache call the upx call
      );

      return syncDfd;
    },

    syncFromUpx(method, model, options) {
      if (method == 'read') {
        if (this.collection_method == undefined) {
          throw new Error(
            `No collection method for ${
              this.module}::${this.object}`,
          );
        }

        if (!Connection.isOnline()) {
          const def = $.Deferred();
          def.reject('Cannot make upx call: no connection');

          const ev = new OfflineEvent();
          ev.trigger();

          return def;
        }

        return this.wrapper.call(
          this.module,
          this.collection_method,
          this.params,
          options,
        );
      }
      throw new Error(`Sync method ${method} not supported for UpxCollection`);
    },

    /**
             * Fetches using the options passed untill there is nothing fetchable anymore
             */
    fetchAll(options) {
      const def = new $.Deferred();

      // Initial fetch
      this.fetch(options)
        .then(() => {
          // fetch untill you can't fetch anymore!
          this._fetchAll()
            .then(def.resolve, def.reject);
        }, def.reject);

      return def;
    },

    fetchAllPromise(options) {
      const deferred = this.fetchAll(options);
      return Promisify.deferredToPromise(deferred);
    },

    /**
             * Recursive function
             * @private
             */
    _fetchAll(def) {
      def = def || new $.Deferred();

      // Check if therer is anything more top fetch
      if (this.canFetchNext()) {
        // Fetch more
        this.fetchNext()
          .then(() => {
            // Once done. call it's self
            this._fetchAll(def);
          }, def.reject);
      }
      // Nothing to fetch anymore > done fetching
      else {
        def.resolve();
      }

      return def;
    },

    /**
             * If the collection is loaded or not.
             * @private
             */
    loadDeferred: false,

    /**
     * Wrapper for the private loadDeferred variable
     * @return {boolean}
     */
    isLoaded() {
      return this.loadDeferred && this.loadDeferred.state() === 'resolved';
    },
    isLoading() {
      return this.loadDeferred && this.loadDeferred.state() === 'pending';
    },
    /**
             * The parameters used to load the collection
             * @override to change
             */
    loadParameters: {
      start: 0,
      limit: 500,
    },

    checkIfLoaded() {
      if (!this.isLoaded()) {
        throw new Error(`Collection ${this.module}::${this.object} is not loaded`);
      }
    },
    /**
             * Loads the collection
             * @param reload {boolean} If the collection unloads before loading
             * @return {$.Deferred}
             */
    load({ reload = false } = {}) {
      const def = new $.Deferred();

      if (reload) this.unload();

      if (
        !this.loadDeferred
          || this.loadDeferred.state() === 'rejected'
          || (
            this.loadDeferred.state() === 'resolved'
            && this.lastUpdateField
          )
      ) {
        this.fetchAll({
          params: this.getLoadParameters(),
          add: true,
          remove: false,
          merge: true,
        })
          .then(() => {
            if (this.lastUpdateField) {
              this.lastUpdated = new Moment().format();
            }
            def.resolve();
          }, def.reject);
        this.loadDeferred = def.promise();
      }
      return this.loadDeferred;
    },

    getLoadParameters() {
      const params = JSON.parse(JSON.stringify(this.loadParameters));
      params.filters = params.filters || [];
      if (this.lastUpdateField && this.lastUpdated) {
        const name = `${this.lastUpdateField}__>=`;
        params.filters.push(
          {
            name,
            val: this.lastUpdated,
          },
        );
      }
      return params;
    },

    /**
             * Loads the collection using native promises
             * @param reload {boolean} If the collection unloads before loading
             * @return {Promise}
             */
    loadPromise({ reload = false } = {}) {
      const deferred = this.load({ reload });
      return Promisify.deferredToPromise(deferred);
    },

    /**
             * Unloads the collection
             */
    unload() {
      this.reset();
      this.loadDeferred = false;
    },
    reload() {
      return this.load({ reload: true });
    },
  });
});
