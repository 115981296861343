define((require) => {
  // get the application settings
  const application = require('application');
  const $ = require('jquery');
  const { settings } = application;

  // modules we should start
  const modules = [
    'common',
    'pwa',
    'upx',
    'profile',
    'shop',
  ];

  // Adding some items to the admin settings.
  if (!settings.admin) { settings.admin = {}; }
  settings.admin = $.extend(settings.admin, {
    disabledRouters: ['authentication', 'profile'],
    locales: ['nl', 'en', 'de'],
  });

  if (!settings.upx) { settings.upx = {}; }
  settings.upx.client_name = 'Backoffice';

  require('suite/styles/index.less');

  const loadModule = (module) => require(`modules/${module}/application`);

  // apply theme
  if (window.location.host === 'venotest.storekeepercloud.com') {
    $('#storekeeper-theme').addClass('veno-theme');
  }
  return async () => {
    // start the modules
    for (const i in modules) {
      const key = modules[i];
      const app = loadModule(key);
      const appSettings = settings[key] || {};
      const result = app.start(appSettings);
      if (result instanceof Promise) {
        await result;
      }
    }
  };
});
